import React, { useState } from "react";
import FAQList from "./faq-list-modern";
import { allFAQs } from "./faq.constants";
import { FAQ } from "./faqs.types";

const SportsBettingFAQs: React.FC = () => {
  return (
    <div className="text-center py-12 bg-[#0f172a] text-white px-4 dark-bloo-bg ">
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-4">
        Sports Betting 101
      </h1>
      <p className="text-lg sm:text-xl mb-8 pb-4">Let's get into the basics of profitable sports betting</p>
      <FAQList faqs={allFAQs} />
    </div>
  );
};

export default SportsBettingFAQs;
