// TableFilterForm.tsx
import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TableFilter } from "./table-filter.types";
import { tableFilterFormSchema } from "./table-filter-form-schema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
// import { format } from "date-fns";
// import { DateRange } from "react-day-picker";
// import { DatePickerWithRange } from "../date-picker-range";
// import { CheckboxList } from "./checkbox-list";
// import { Checkbox } from "@radix-ui/react-checkbox";
// import BlackList from "../../../../components/blacklist/Blacklist";
import BookieSelector from "../bookie-selector";
import LeagueSelector from "../league-selector";
import { postRequest } from "../../../../services/apiService";
import { bookieLitesSignal, getEVRowData } from "../../positive-ev/positive-ev";
import { signal } from "@preact/signals-react";
import { currentFilterSignal, filtersSignal } from "./table-filter-views";

const schema = tableFilterFormSchema;

export const showPopover = signal(true);

function formatToUTCStartOfDay(dateString: string): string {
  if (!dateString) return "";

  // Parse the date string into a Date object
  const dateObj = new Date(dateString);

  // Adjust the date to the start of the day in local time
  dateObj.setHours(0, 0, 0, 0);

  // Get the time-zone offset in minutes and convert it to milliseconds
  const timezoneOffset = dateObj.getTimezoneOffset() * 60 * 1000;

  // Adjust the date to UTC by adding the timezone offset
  const utcDate = new Date(dateObj.getTime() - timezoneOffset);

  // Format to UTC
  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth() + 1; // getUTCMonth() returns months from 0-11
  const day = utcDate.getUTCDate();

  // Format month and day to ensure two digits
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}T00:00:00Z`;
}

function resetMessageSignals() {
  filterSaveSuccesSignal.value = "";
  filterSaveErrorSignal.value = "";
}

function isFilterEffective(filter: TableFilter): boolean {
  // Check if a string or array is empty, undefined, or null
  const isEmpty = (value: string | string[] | undefined): boolean => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === undefined || value === null || value === "";
  };

  // Check each field except 'name' and 'isActive'
  return !(
    isEmpty(filter.oddsMin) &&
    isEmpty(filter.oddsMax) &&
    isEmpty(filter.bookies) &&
    isEmpty(filter.leagues) &&
    isEmpty(filter.dateMin) &&
    isEmpty(filter.dateMax) &&
    isEmpty(filter.percentMin) &&
    isEmpty(filter.percentMax)
  );
}

type TableFilterFormProps = {
  filter?: TableFilter;
  onFilterUpdate: (filter: TableFilter, updateType: string) => void;
  // setSelectedFilter?: (filter: TableFilter) => void;
  currentFilter?: TableFilter | null;
  mode?: string;
};

//const numOfFilters =filtersSignal.value.length;
// const leagues = ["basketball_ncaab", "basketball_nba"];
// const bookies = ["pointsbetus", "betmgm", "draftkings", "betrivers"];
const showFormSignal = signal(false);

const filterSaveSuccesSignal = signal("");
const filterSaveErrorSignal = signal("");

const handleEditButtonClick = () => {
  //console.log(showFormSignal.value, "b4showFormSignal.value::");
  showFormSignal.value = true;
  // console.log(showFormSignal.value, "aftashowFormSignal.value::");
};

const handleCloseButtonClick = () => {
  //console.log(showFormSignal.value, "b4showFormSignal.value::");
  showFormSignal.value = false;
  //console.log(showFormSignal.value, "aftashowFormSignal.value::");
};

export const TableFilterForm: React.FC<TableFilterFormProps> = ({
  filter,
  onFilterUpdate,
  currentFilter,
  mode,
}) => {
  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      resetMessageSignals();
    };
  }, []);

  const bookiesChangedSignal = signal(false);
  const leaguesChangedSignal = signal(false);

  const form = useForm<TableFilter>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: currentFilter?.name ?? `Filter ${filtersSignal.value.length + 1}`,
      oddsMin: currentFilter?.oddsMin?.toString() ?? undefined,
      oddsMax: currentFilter?.oddsMax?.toString() ?? undefined,
      bookies: currentFilter?.bookies ?? [],
      leagues: [],
      dateMin: "",
      dateMax: "",
      percentMin: currentFilter?.percentMin?.toString() ?? undefined,
      percentMax: currentFilter?.percentMax?.toString() ?? undefined,
      isActive: currentFilter?.isActive ?? false,
    },
  });

  const { reset } = form;
  const { isDirty, isValid } = form.formState;

  const [action, setAction] = useState("apply");

  const onSubmit: SubmitHandler<TableFilter> = async (data, event) => {
    const filterId = filter?.id;
    if (filterId) {
      data.id = filterId;
    }

    resetMessageSignals();

    const isNoEffectFilter = !isFilterEffective(data as TableFilter);

    if (isNoEffectFilter) {
      filterSaveErrorSignal.value =
        "ERROR: this filter will not have any effect, please add some criteria";
      return;
    }

    try {
      let savedFilterData;
      if (action !== "save") {
        // "Save & Apply" action
        savedFilterData = await postRequest(
          "/api/v1/table-filters/save-apply",
          data
        );
        onFilterUpdate(savedFilterData, "saveAndApply");
        filterSaveSuccesSignal.value =
          savedFilterData.name + " has been saved and applied!";
      } else {
        // "Save" only action
        savedFilterData = await postRequest("/api/v1/table-filters", data);
        onFilterUpdate(savedFilterData, "save");
        filterSaveSuccesSignal.value =
          savedFilterData.name + " has been saved successfully!";
      }

      if (mode === "create") {
        reset(currentFilter ?? ({} as TableFilter));
      }

      getEVRowData();
    } catch (error) {
      console.error(
        "ERROR: filter didnt save succesfully, please try again:",
        error
      );
      filterSaveErrorSignal.value =
        "Failed to save filter: " + (error as Error).message;
    }
  };

  const handleLeaguesSelectionChange = (selectedLeagues: string[]) => {
    form.setValue("leagues", selectedLeagues);
    leaguesChangedSignal.value = true;
  };

  const handleBookiesSelectionChange = (selectedBookies: string[]) => {
    form.setValue("bookies", selectedBookies);
    bookiesChangedSignal.value = true;
  };

  const showForm = showFormSignal.value || mode === "create";

  return (
    <>
      {!showForm && (
        <Button
          disabled={false}
          className="m-1"
          type="submit"
          onClick={() => handleEditButtonClick()}
        >
          Edit
        </Button>
      )}

      {showFormSignal.value && mode !== "create" && (
        <Button
          disabled={false}
          className="m-1"
          type="submit"
          onClick={() => handleCloseButtonClick()}
        >
          Close
        </Button>
      )}

      {showForm && (
        <Form {...form}>
          {mode === "create" && (
            <h4 className="text-lg md:text-xl font-semibold text-gray-600 pt-4">
              Create a Filter
            </h4>
          )}
          {mode !== "create" && (
            <h4 className="text-lg md:text-xl font-semibold text-gray-600 pt-2">
              Edit Filter
            </h4>
          )}
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-2">
            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Filter Name" {...field} />
                  </FormControl>
                  {fieldState.error && (
                    <FormMessage>{fieldState.error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            {/* Fields for 'oddsMin' and 'oddsMax' */}
            <div className="flex space-x-4">
              <FormField
                control={form.control}
                name="oddsMin"
                render={({
                  field,
                  fieldState,
                }: {
                  field: FieldValues;
                  fieldState: { error?: FieldError };
                }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Minimum Odds</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Minimum Odds"
                        {...field}
                      />
                    </FormControl>
                    {fieldState.error && (
                      <FormMessage>{fieldState.error.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="oddsMax"
                render={({
                  field,
                  fieldState,
                }: {
                  field: FieldValues;
                  fieldState: { error?: FieldError };
                }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Maximum Odds</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Maximum Odds"
                        {...field}
                      />
                    </FormControl>
                    {fieldState.error && (
                      <FormMessage>{fieldState.error.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="flex space-x-4">
              <FormField
                control={form.control}
                name="percentMin"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Min %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Min Percent"
                        {...field}
                      />
                    </FormControl>
                    {fieldState.error && (
                      <FormMessage>{fieldState.error.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="percentMax"
                render={({ field, fieldState }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Max %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Max Percent"
                        {...field}
                      />
                    </FormControl>
                    {fieldState.error && (
                      <FormMessage>{fieldState.error.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
            </div>

            {/* <FormItem>
         <FormLabel>Date(s)</FormLabel>
         <DatePickerWithRange onDateChange={handleDateRangeChange} />
       </FormItem>
       */}

            {/* <FormField
 control={form.control}
 name="bookies"
 render={({ field, fieldState }) => (
   <FormItem>
     <FormLabel>Bookies</FormLabel>
     <BookieSelector
       onChange={(selectedBookies) => field.onChange(selectedBookies)}
       initialBookmakers={currentFilter?.bookies ?? []}
     />
     {fieldState.error && (
       <FormMessage>{fieldState.error.message}</FormMessage>
     )}
   </FormItem>
 )}
/> */}
            <FormItem>
              <FormLabel>
                Bookies{" "}
                <span className="font-semibold text-gray-600">
                  (Coming Soon)
                </span>
              </FormLabel>
              <div className="relative">
                {/* <div className="opacity-">
                  <BookieSelector
                    onChange={handleBookiesSelectionChange}
                    initialBookmakers={currentFilter?.bookies ?? []}
                    bookmakers={bookieLitesSignal.value}
                  />
                </div> */}
           
                {/* <div className="absolute top-0 left-0 right-0 bottom-0"></div> */}
              </div>
            </FormItem>

            <FormItem>
              <FormLabel>Leagues</FormLabel>
              <LeagueSelector
                onChange={handleLeaguesSelectionChange}
                initialLeagues={currentFilter?.leagues ?? []}
              />
            </FormItem>

            {filterSaveSuccesSignal.value && (
              <div
                style={{
                  backgroundColor: "#D1E7DD",
                  color: "#0F5132",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #BADBCC",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                {filterSaveSuccesSignal.value}
              </div>
            )}
            {filterSaveErrorSignal.value && (
              <div
                style={{
                  backgroundColor: "#F8D7DA",
                  color: "#842029",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #F5C2C7",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                {filterSaveErrorSignal.value}
              </div>
            )}

            <div className="m-2 flex justify-end">
              <Button
                disabled={false}
                className="mx-1"
                type="submit"
                onClick={() => setAction("save")}
              >
                Save
              </Button>
              <Button
                className="mx-1"
                type="submit"
                onClick={() => setAction("apply")}
              >
                Save & Apply
              </Button>
            </div>
          </form>
        </Form>
      )}
    </>
  );
};
