import VideoPlayer from "../../../components/landing-page/video-player/VideoPlayer";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../login/login.module.scss";
import YouTube from "react-youtube";
import { postRequest } from "../../../services/apiService";
import { Helmet } from "react-helmet-async";
import { createATag } from "../../../services/common-util-service";

type ArbitrageEVUnAuthedProps = {
  authed: boolean;
};

const ArbitrageComingSoon: React.FC<ArbitrageEVUnAuthedProps> = ({ authed }) => {

  return (
    <>
      <Helmet>
        <title>Arbitrage Betting Tool - Arbitrage Bets | BookieBrawler</title>
        <link rel="canonical" href={'https://bookiebrawler.com/arbitrage'} />
        <meta
          name="description"
          content="Arbitrage betting software designed to guarantee profits by displaying exploitable differences in odds between sportsbooks. Comprehensive Arbitrage Finder, with real-time updates and actionable opportunities."
        />
      </Helmet>
 
      <div className="container mx-auto">
        <div className="row g-0 lg:m-5 mb-0">
          <div
            className="col-md-6 d-flex flex-column justify-content-between"
            style={{ padding: "2rem" }}
          >
            <div>
              <h5 style={{ fontSize: "1em" }} className="font-normal mb-4">
                <span className={styles.shine + " font-lg-bloo"}>
                  Coming Soon{" "}
                </span>
                | Arbitrage
              </h5>
              <h1 className="tracking-wide font-extrabold text-left lg:text-5xl md:text-2xl text-xl font-code-next text-brand-gray-9 mb-4">
                Bet both sides to lock in risk free profits.
              </h1>
              <h1 className="tracking-wide font-extrabold text-left lg:text-xl md:text-lg text-md font-code-next text-brand-gray-9 mb-5">
                We find you arbitrage betting opportunities. Coming very soon
              </h1>
            </div>
            <div>
            
       
            {createATag("betting-education/arbitrage","What's Arbitrage")}
            {createATag("betting-calculators/arbitrage-hedge-bet","Arbitrage Calculator")}
            {createATag("login","Notify Me At Release")}
            </div>
          </div>
          <div className="col-md-6 my-auto">
            <YouTube
              videoId={"KIh69IspZyc"}
              opts={{
                width: "100%",
                height: "300px",
                playerVars: {
                  autoplay: 1,
                },
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="text-center p-4">
              <h5
                className={
                  "text-sm font-normal uppercase tracking-wide font-small font-med-bloo " +
                  styles.shine
                }
              >
                How does it work?
              </h5>
              <h3 className="lg:text-7xl md:text-3xl text-xl mb-2 font-extrabold italic">
                The Arbitrage Tool
              </h3>
              <p className="text-md mx-auto" style={{ maxWidth: "675px" }}>
                Finding bets with lower risk is extremely time consuming... but our tool finds them in seconds, automatically. Exploit differences in odds between sportsbooks, and guarantee yourself a profit no matter who wins.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArbitrageComingSoon;
