import React, { useState } from "react";
import { BookieImage } from "../../../../products/common/bookie-image";
import {
  filterBetsWithDifferentPrice,
  formatOdd,
} from "../../services/positive-ev-service";
import { PositiveEVRow } from "../../types/positive-ev.types";
import MarketView from "../market-view/market-view";
import { placeHolderBet, placeHolderBets } from "./bet-cell.constants";

interface BookiesCellProps {
  positiveEVRow: PositiveEVRow;
}

const BookiesCell: React.FC<BookiesCellProps> = ({ positiveEVRow }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  let awayBets = positiveEVRow.away_bets;
  let homeBets = positiveEVRow.home_bets;

  if (!awayBets && !homeBets) return null;

  if (!awayBets || awayBets.length === 0) {
    awayBets = [placeHolderBet, placeHolderBet];
  }
  if (!homeBets || homeBets.length === 0) {
    homeBets = [placeHolderBet, placeHolderBet];
  }

  const bestAwayBet = awayBets?.[0];
  const bestHomeBet = homeBets?.[0];

  const hasAwayEV = positiveEVRow.away_evpercent > 0;
  const hasHomeEV = positiveEVRow.home_evpercent > 0;
  const homePrice = bestHomeBet?.price;
  const awayPrice = bestAwayBet?.price;

  const awayBetMaxes = awayPrice
    ? filterBetsWithDifferentPrice(awayBets, awayPrice)
    : placeHolderBets;
  const homeBetMaxes = homePrice
    ? filterBetsWithDifferentPrice(homeBets, homePrice)
    : placeHolderBets;

  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100%",
    position: "relative", // Allows positioning of the "X" button
  };

  return (
    <div style={style}>
      {bestAwayBet && (
        <div
          className={`my-2 p-2 w-100 d-flex align-items-center ${
            hasAwayEV ? "positive-ev" : "positive-ev-other"
          }`}
          style={{ padding: '0 8px' }} // Adjusts padding to prevent smushing
        >
          <div className="d-flex align-items-center flex-grow-1">
            <div className="odds mr-2" style={{ minWidth: "35px" }}>
              {formatOdd(awayPrice ?? 0)}
            </div>
            <div className="d-flex flex-row align-items-center">
              {awayBetMaxes.map((bet, index) => (
                <BookieImage key={index} bookie={bet.bookie} />
              ))}
            </div>
          </div>
          <div style={{ minWidth: "30px", marginLeft: "auto" }}>
            <InfoIconButton onClick={toggleModal} />
          </div>
        </div>
      )}
      {bestHomeBet && (
        <div
          className={`my-2 p-2 w-100 d-flex align-items-center ${
            hasHomeEV ? "positive-ev" : "positive-ev-other"
          }`}
          style={{ padding: '0 8px' }} // Adjusts padding to prevent smushing
        >
          <div className="d-flex align-items-center flex-grow-1">
            <div className="odds mr-2" style={{ minWidth: "35px" }}>
              {formatOdd(homePrice ?? 0)}
            </div>
            <div className="d-flex flex-row align-items-center">
              {homeBetMaxes.map((bet, index) => (
                <BookieImage key={index} bookie={bet.bookie} />
              ))}
            </div>
          </div>
          <div style={{ minWidth: "30px", marginLeft: "auto" }}>
            <InfoIconButton onClick={toggleModal} />
          </div>
        </div>
      )}
  
      {/* MarketView Modal */}
      {isModalOpen && (
        <MarketView
          evRow={positiveEVRow}
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          //fairOdds={[positiveEVRow.fair_odds_away, positiveEVRow.fair_odds_home]}
        />
      )}
    </div>
  );
  
  
  
};

export default BookiesCell;

interface InfoIconButtonProps {
  onClick: () => void;
  width?: string;
  height?: string;
}

const InfoIconButton: React.FC<InfoIconButtonProps> = ({
  onClick,
  width = "24px",
  height = "24px",
}) => {
  return (
    // <button
     
    //   className="p-1"
    //   aria-label="Close"
    //   onClick={onClick}
    //   style={{ zIndex: 1, alignSelf: 'flex-start', marginLeft: 'auto' }}
    // >
      <img
        src="https://storage.googleapis.com/assestsbookiebrawler/icons/info-icon.png"
        alt="Info Icon"
        className="scalable"
        onClick={onClick}
        style={{
          width: width,
          height: height,
          cursor: "pointer",
        }}
      />
    // </button>
  );
};

