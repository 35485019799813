import React from "react";
import PokerHandRankingsInfo from "../betting-education/poker-writeup";
import { ContentImage, ContentPost } from "../content-post/content-post-model"; // Adjust the import path as needed
import CasinoCardDeckInfo from "./casino-how-many-cards-writeup";
import SlotMachineStrategies from "./how-to-win-writeup";
import CardShufflingTechniques from "./shuffle-writeup";
import SlotMachineSecurityInfo from "./slot-hack-writeup";

export const homeRunTodayImage: ContentImage = {
  id: 4,
  title: "Home Run Bets Today Image (Orioles Fans)",
  source: "MLB.com",
  imageUrl:
    "https://securea.mlb.com/assets/images/3/1/4/97375314/cuts/BAL_rallytowels_wbis4nhm_fujhqc19.jpg",
};

export const homeRunTodayImage2: ContentImage = {
  id: 4,
  title: "how many cards are in a casino deck",
  source: "Getty Images",
  imageUrl: "https://ichef.bbci.co.uk/images/ic/1920xn/p0d81vn5.jpg.webp",
};

export const pokehandImage: ContentImage = {
  id: 4,
  title: "What Beats What in Poker",
  source: "happy_author/stock.adobe.com",
  imageUrl:
    "https://cdn.britannica.com/73/244173-050-13235B84/Royal-Flush-poker-card-game-gambling.jpg?w=600&q=60",
};

export const shufflingImage: ContentImage = {
    id: 4,
    title: "What Beats What in Poker",
    source: "888 casino",
    imageUrl:
      "https://www.888casino.com/blog/sites/newblog.888casino.com/files/styles/hp_headline_big/public/2022-08/2016_dec_888_dedicated_environment_live_blackjack_male_dealer_card_tricks_12%20%281%29.webp?itok=8eyd9yi8",
  };

  export const hackCasinoImage: ContentImage = {
    id: 4,
    title: "Slot machine",
    source: "BookieBrawler",
    imageUrl:
      "https://img.freepik.com/premium-photo/vintage-slot-machine-playing-casino-slot-machine_124507-45736.jpg",
  };

  export const slotMachineStrategiesImage: ContentImage = {
    id: 4,
    title: "Slot machine",
    source: "BookieBrawler",
    imageUrl:
      "https://www.aussietheatre.com.au/wp-content/uploads/2024/08/bard-630x343.png",
  };

  

export const casinoBlogPosts: ContentPost[] = [
  {
    id: 5,
    title: "How Many Cards are in a Deck?",
    slug: "how-many-cards-are-in-a-deck",
    image: homeRunTodayImage2,
    summary:
      "How Many Cards are in a Deck? Find out the basics of cards from BookieBrawler",
    content: <CasinoCardDeckInfo />,
    author: "Marshall",
    publishedDate: new Date(),
    coverPhoto: homeRunTodayImage2.imageUrl,
  },
  {
    id: 5,
    title: "What Beats What in Poker?",
    slug: "what-beats-what-in-poker",
    image: pokehandImage,
    summary:
      "Does flush beat full house? Does a straight beat 2 pair? Demystify the inner workings of poker by reading this piece by the BookieBrawler team",
    content: <PokerHandRankingsInfo />,
    author: "Marshall",
    publishedDate: new Date(),
    coverPhoto: pokehandImage.imageUrl,
  },
  {
    id: 5,
    title:
      "How to Shuffle Cards: Techniques to Shuffle a Deck of Playing Cards",
    slug: "how-to-shuffle-cards-techniques-to-shuffle-a-deck-of-playing-cards",
    image: shufflingImage,
    summary:
      "How to Shuffle Cards: Techniques to Shuffle a Deck of Playing Cards",
    content: <CardShufflingTechniques/>,
    author: "Marshall",
    publishedDate: new Date(),
    coverPhoto: shufflingImage.imageUrl,
  },
  {
    id: 5,
    title:
      "How to Cheat at a Slot Machine: Is it Possible to Cheat Slot Machines?",
    slug: "how-to-cheat-a-slot-machine",
    image: hackCasinoImage,
    summary:
      "How to Cheat at a Slot Machine: Is it Possible to Cheat Slot Machines?",
    content: <SlotMachineSecurityInfo/>,
    author: "Marshall",
    publishedDate: new Date(),
    coverPhoto: hackCasinoImage.imageUrl,
  },
  {
    id: 5,
    title:
      "How to Win on Slot Machines – Best Slot Machine Strategies",
    slug: "how-to-win-on-slot-machines",
    image: slotMachineStrategiesImage,
    summary:
      "How to Win on Slot Machines – Best Slot Machine Strategies",
    content: <SlotMachineStrategies/>,
    author: "Marshall",
    publishedDate: new Date(),
    coverPhoto: slotMachineStrategiesImage.imageUrl,
  },


  
];
