import React from 'react';
import './linear-indeterminate.css'; // Assuming you put the CSS in this file

const LinearIndeterminate: React.FC = () => {
  return (
    <div className="linear-indeterminate">
      <div className="progress-bar"></div>
    </div>
  );
};

export default LinearIndeterminate;
