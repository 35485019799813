import React from "react";
import { userStateSignal } from "./promo-finder";
//import { userStateSignal } from "./signals"; // Adjust the import path as needed

// List of U.S. states for the dropdown
const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
  "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
  "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
  "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", 
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", 
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", 
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

const StateDropdown: React.FC = () => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    userStateSignal.value = event.target.value;
  };

  return (
    <div className="max-w-[60vw] flex justify-center items-center">
      <select 
        className="form-select block w-full max-w-xs px-5 py-2 border-2 border-blue-500 rounded-md shadow-sm text-gray-700 bg-powder-bloo focus:outline-none focus:border-blue-700 transition duration-200 ease-in-out"
        onChange={handleSelect}
        defaultValue=""
      >
        <option value="" disabled>Select your state</option>
        {states.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateDropdown;
