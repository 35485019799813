import { formatDollarAmount } from "../../../services/common-util-service";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { fetchUserState, PromoCard, userStateSignal } from "./promo-finder";
import { getPromosByState } from "./promo-finder.service";
import StateDropdown from "./state-select";

export const AllPromos: React.FC = () => {
  const state = userStateSignal.value;

  //   useEffect(() => {
  //     fetchUserState();
  //   }, []);
  const filteredPromos = getPromosByState(state);
  const totalPromoAmount = filteredPromos.reduce(
    (sum, promo) => sum + promo.promoAmount,
    0
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>Sportsbook Promo Finder | BookieBrawler</title>
        <meta
          name="description"
          content="Use our free promo finder to see what sign up bonuses are available in your state"
        />
      </Helmet>

      <section>
        <div className="py-10 px-3 text-center text-white">
          <div className="flex items-center">
            <div className="flex items-center justify-between space-x-4 w-full">
              <img
                src={
                  "https://storage.googleapis.com/assestsbookiebrawler/icons/back-icon.png"
                }
                alt={"Go Back"}
                className="w-[35px] h-[35px] lg:ml-5 rounded-md"
                onClick={() => window.history.back()}
              />
              <StateDropdown />
            </div>
          </div>
          <div className="min-w-[300px] max-w-[85%] mx-auto">
            {/* Heading */}
            <h1 className="lg:text-4xl md:text-3xl text-3xl pt-5 tracking-wide">
              Get up to{" "}
              <span className="text-blue-400">
                <strong>{formatDollarAmount(totalPromoAmount)}</strong>
              </span>{" "}
              in promos, just by signing up with{" "}
              <span className="text-blue-400 capitalize">
                <strong>{state}</strong>
              </span>{" "}
              sportsbooks.
            </h1>
            <p className="lg:text-2xl md:text-xl text-md mt-5 pb-2">
              Sportsbooks hand out ALOT of bonus bet credits to get you to try
              them. We’ll show you exactly how to take advantage of these
              promos.
            </p>
          </div>

          {/* All Promo Cards in One Column */}
          <div className="flex flex-col gap-4 mt-8 items-center">
            {filteredPromos.map((promo) => (
              <PromoCard key={promo.bookieKey} promo={promo} />
            ))}
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
