import React from 'react';

const PointSpreadInfo: React.FC = () => {
  return (
    <>
      <p className="py-3 text-powder-bloo">
        <h2><strong>What is a Point Spread?</strong></h2>
      </p>
      <p className="py-4 text-white text-opacity-75">
        A point spread is a type of sports bet based on the expected margin of victory in a game or match. It’s designed to make each matchup more balanced, setting a margin by which the favored team is expected to win. This makes betting more appealing, as it creates nearly equal odds for both sides.
      </p>
      <p className="py-4 text-white text-opacity-75">
        In sports betting, games are rarely seen as 50-50 or "pick 'em" matchups. When one team is heavily favored, sportsbooks use point spreads to even the playing field. Without spreads, betting on the favorite at the same odds as the underdog would lead to easy profits for bettors, which isn’t sustainable for sportsbooks.
      </p>
      <p className="py-4 text-white text-opacity-75">
        For example, during Week 14 of the 2021 NFL season, the 8-4 Tennessee Titans faced the 2-10 Jacksonville Jaguars. If sportsbooks offered equal odds for both teams to win, bettors would simply bet on the Titans, who were expected to win. To make it fair, sportsbooks introduced a point spread, requiring the Titans to win by a certain margin to cover the spread.
      </p>

      <h3 className="py-4 text-powder-bloo">How Does Spread Betting Work?</h3>
      <p className="py-4 text-white text-opacity-75">
        Spread betting is an alternative to moneyline betting, which is a wager on a team to win outright. With a moneyline bet, heavily favored teams offer low payouts, as their victory is more likely. Spread betting allows bettors to wager on whether a team will win by a certain margin, typically offering -110 odds on both sides.
      </p>
      <p className="py-4 text-white text-opacity-75">
        For favorites, bettors can bet on the team to cover the spread, meaning they must win by more than the set margin. For underdogs, spread betting allows bettors to take a safer approach by betting on them to keep the game close, even if they don’t win outright. Bettors can also choose custom spreads, which can offer higher payouts if the bettor predicts a more significant margin of victory.
      </p>

      <h3 className="py-4 text-powder-bloo">What Do -2, -3.5, and -7 Mean in Point Spreads?</h3>
      <p className="py-4 text-white text-opacity-75">
        In point spreads, a negative number (e.g., -2) indicates the favorite, while a positive number (e.g., +2) designates the underdog. Here’s a quick breakdown:
      </p>
      <ul className="list-disc list-inside py-4 text-white text-opacity-75">
        <li><strong>-2:</strong> The favorite must win by more than two points. A win by exactly two points results in a “push,” returning the bet without any winnings.</li>
        <li><strong>-3.5:</strong> The favorite must win by more than 3.5 points. In this case, there’s no possibility of a push—only a win or a loss.</li>
        <li><strong>-7:</strong> The favorite needs to win by more than seven points to cover. If they win by exactly seven, it’s a push.</li>
      </ul>
      <p className="py-4 text-white text-opacity-75">
        These spreads vary based on the sport. For example, a -3.5 spread in the NFL means a field goal margin wouldn’t cover, requiring a larger win for success.
      </p>

      <h3 className="py-4 text-powder-bloo">Examples of Point Spreads</h3>
      <p className="py-4 text-white text-opacity-75">
        To balance bets, sportsbooks often set a point spread that favors the stronger team by a specific margin. In our Titans vs. Jaguars example, sportsbooks set the Titans as 8.5-point favorites. This meant bettors on the Titans needed them to win by 9 or more points, while Jaguars bettors would win if the Jaguars won or lost by less than 8.5 points. Tennessee ended up winning 20-0, so bets on the Titans’ point spread won, while Jaguars bets lost.
      </p>

      <h3 className="py-4 text-powder-bloo">How to Read Point Spreads</h3>
      <p className="py-4 text-white text-opacity-75">
        Point spreads are usually displayed with a “+” or “-” next to the number. The “-” symbol indicates the favorite, while the “+” indicates the underdog. For example, the Titans might be listed as “-8.5” and the Jaguars as “+8.5.” This format suggests that the Titans are favored to win by 8.5 points, creating a scenario where both teams theoretically have an equal chance of covering the spread.
      </p>
      <p className="py-4 text-white text-opacity-75">
        Most point spread bets are offered at -110 odds on both sides, meaning bettors risk $110 to win $100. This difference, known as the “vig” or “juice,” allows sportsbooks to make a profit regardless of the game’s outcome. In cases where the odds differ slightly, such as -115/-105, this could reflect the sportsbook’s adjustment based on betting activity or anticipated risk.
      </p>

      <h3 className="py-4 text-powder-bloo">How Are Point Spreads Made and Calculated?</h3>
      <p className="py-4 text-white text-opacity-75">
        Sportsbooks set point spreads using data-driven algorithms and expert insights. Factors like statistical power ratings, injuries, travel, past matchups, and home-field advantage all influence the spread. For instance, NFL home teams generally receive a two to three-point advantage. So, if the Titans and Jaguars were playing at a neutral site, the spread might be closer to 6 points instead of 8.5.
      </p>
      <p className="py-4 text-white text-opacity-75">
        Point spreads fluctuate based on betting activity and various influencing factors. In the Titans vs. Jaguars example, the line opened at -9 but shifted to -8.5 before kickoff. Significant bets, or “steam moves,” can push sportsbooks to adjust lines to balance the action on both sides. Additionally, sharp bettors, known for their consistent success, can influence line movements by placing large bets on one side.
      </p>
      <p className="py-4 text-white text-opacity-75">
        Timing your bet is essential with point spreads. If you spot an opening line that you expect to move, betting early can be advantageous. A favorable line move gives you a potential “positive EV” (expected value) bet and allows for opportunities to “middle” or “arbitrage” your bet, maximizing profitability.
      </p>

      <p className="py-4 text-white text-opacity-75">
        By understanding how point spreads work and how sportsbooks adjust them, you can make more informed betting decisions. And remember, tracking odds across different sportsbooks, like with BookieBrawler, can help you find the best value for your bets.
      </p>
    </>
  );
};

export default PointSpreadInfo;
