import React, { useState, useEffect } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProfileImage from "../account/ProfileImage";
import {
  getCurrentUser,
  getIsAuthenticated,
  getProfilePic,
} from "../../services/apiService";
import DarkMode from "../commons/DarkMode/DarkMode";
import { useDarkMode } from "../commons/DarkMode/useDarkMode";
import { User } from "../types/User";
import { useLogout } from "./useLogout";
import { useUser } from "./UserContext";

interface ProfileIconProps {
  //user: User;
  onSignOut: () => void;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({ onSignOut }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [profilePic, setProfilePic] = useState<string | null>();

  const { isDarkMode, setIsDarkMode } = useDarkMode();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const navigate = useNavigate();

  const loadAccountSettings = () => {
    navigate("/account-settings");
  };

  const loadPositiveEV = () => {
    navigate("/positive-ev");
  };

  const loadSuggestions = () => {
    navigate("/suggestions");
  };
  const handleLogoutContext = useLogout();

  const handleLogout = async () => {
    try {
      await onSignOut(); // ensure that sign out process is complete
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Re-check authentication status
      setIsUserAuthenticated(await getIsAuthenticated());
      window.location.href = "/";

      if (isUserAuthenticated) {
        console.error("Logout failed: user is still authenticated.");
      } else {
        console.log("You've been logged out successfully.");
        handleLogoutContext();
      }
    } catch (error) {
      console.error("An error occurred during logout: ", error);
      // Optionally, you could navigate to an error page here
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      // applyStyles();
    }
  }, [dropdownOpen]);

  useEffect(() => {
    console.log(getProfilePic(),"getprofile");
    setProfilePic(getProfilePic());
  }, []);


  const userProfilePic=getProfilePic();

  const imageUrl =   userProfilePic && 'null' != userProfilePic
  ? userProfilePic
  : "https://storage.googleapis.com/assestsbookiebrawler/icons/profile-default.png";


  console.log(imageUrl,"imageUrl::",userProfilePic,"userProfilePic");
  return (
    <Dropdown
      show={dropdownOpen}
      onClick={toggleDropdown}
      className="on-dropdown"
    >
      <Dropdown.Toggle as="div" id="dropdown-basic">
        <ProfileImage imageUrl={imageUrl} size={40}></ProfileImage>
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu">
        <Dropdown.Item
          as={Link}
          to="/account-settings"
          className="custom-dropdown-item"
        >
          Profile
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/positive-ev"
          className="custom-dropdown-item"
        >
          Positive EV
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/suggestions"
          className="custom-dropdown-item"
        >
          Suggestions
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/blog" className="custom-dropdown-item">
          Blog
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/betting-calculators"
          className="custom-dropdown-item"
        >
          Calculators
        </Dropdown.Item>

        <Dropdown.Item
          as={Link}
          to="/betting-education"
          className="custom-dropdown-item"
        >
          Education
        </Dropdown.Item>
        <Dropdown.Item
          className="custom-dropdown-item"
          eventKey="4"
          onClick={handleLogout}
        >
          Sign Out
        </Dropdown.Item>
        {/* <span> <DarkMode isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/></span> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileIcon;
