import React, { useState } from 'react';
import FAQList from './faq-list-modern';
import { FAQ } from './faqs.types';



const faqItems: FAQ[] = [
    {
      question: 'How Does BookieBrawler Work?',
      answer: 'Sportsbooks aim to offer a wide variety of bets, but they often make small mistakes in the odds they provide. These minor errors, sometimes just 1-2%, can add up to a significant advantage for you over time. This concept is similar to the strategies used by Wall Street traders and hedge fund managers. BookieBrawler identifies these "imperfect bets" so you can make informed decisions and gain an edge with every bet you place.'
    },
    {
      question: 'How many sportsbooks do I need?',
      answer: 'The more, the better! Signing up for multiple sportsbooks is not only smart because of the sign-up bonuses, but it\'s also essential for maximizing your BookieBrawler experience. You never know which sportsbook will have the best odds at any given moment, so having accounts with as many as possible ensures you\'re always prepared. Plus, we make the sign-up process easy for you.'
    },
    {
      question: 'How long does this take per day?',
      answer: 'Most users find success by spending just 10-15 minutes a day. However, the more bets you place, the more profitable you\'ll be. Ideally, you should check for the best bets in the morning, during the day, and at night, and place as many bets as possible to maximize your profits.'
    },
    {
      question: 'Will I win every day?',
      answer: 'No — We can\'t guarantee you\'ll win every bet or every day. That\'s not the reality of sports betting, even for professionals. BookieBrawler helps you find good bets, but not every good bet will win. While you\'re more likely to win with BookieBrawler, it\'s impossible to win every time.'
    },
    {
      question: 'How do I know this isn’t a scam?',
      answer: 'Don\'t just take our word for it — check out some of the succes that our users have had. Our goal is to build long-lasting relationships, and many customers have been with us since we started!'
    },
    {
      question: 'This feels like work',
      answer: 'Winning at sports betting does require consistency, and you have to place the bets yourself, so it\'s not a passive experience.'
    },
    {
      question: 'What if I don’t like it or want to cancel?',
      answer: 'No worries, you can easily cancel your trial if you\'re not satisfied with the product. If you lose money after placing 200+ bets in a month while beating clv you we\'ll refund your subscription'
    }
  ];

const BrawlerFAQs: React.FC = () => {
  return (
    <div className="text-center py-12 bg-[#0f172a] text-white px-4 dark-bloo-bg">
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-4">
        Wait a minute. So what's the catch?
      </h1>
      <p className="text-lg sm:text-xl mb-8 pb-4">
        Here are the most frequently asked questions:
      </p>
      <FAQList faqs={faqItems} />
    </div>
  );
};

export default BrawlerFAQs;

