import { ReactNode } from "react";
import { educationPosts } from "../betting-education/education-data";
import { blogPosts } from "../blog/blog-data";
import { casinoBlogPosts } from "../casino/casino-data";
import { mlbBlogPosts } from "../mlb/mlb-data";
import { nbaBlogPosts } from "../nba/nba-data";
import { sportsbookPosts } from "../online-sportsbooks/sportsbook-data";

export interface ContentPost {
    id: number;
    title: string;
    slug: string;
    summary: string;
    content: ReactNode;
    author: string;
    publishedDate: Date;
    coverPhoto?: string;
    image?: ContentImage;
    date?: Date
}

export interface ContentImage {
  id: number;
  title: string;
  source: string
  imageUrl?: string;
}


export const getRelevantPosts = (contentType?: string): ContentPost[] => {
    switch (contentType) {
      case "Blog":
        return blogPosts;
      case "Betting Education":
        return educationPosts;
      case "Online Sportsbooks":
            return sportsbookPosts;
      case "MLB Blog":
            return mlbBlogPosts;
      case "NBA Blog":
            return nbaBlogPosts;
      case "Casino Blog":
            return casinoBlogPosts;
         
      // Add more cases if you have other types of content
      default:
        return [];
    }
  };


  export const getContentPostUrl = (slug: string): string | null => {
    // Define the mapping of content types to their base URLs
    const contentTypeUrls: Record<string, string> = {
      "Blog": "blog",
      "Betting Education": "betting-education",
      "Online Sportsbooks": "online-sportsbooks",
      "MLB Blog": "mlb",
      "NBA Blog": "nba",
      "Casino Blog": "casino",
    };
  
    // Loop through the content types to find the matching slug
    for (const [contentType, baseUrl] of Object.entries(contentTypeUrls)) {
      const posts = getRelevantPosts(contentType);
      const foundPost = posts.find((post) => post.slug === slug);
  
      if (foundPost) {
        return `https://bookiebrawler.com/${baseUrl}/${slug}`;
      }
    }
  
    // Return null if no matching slug was found
    return null;
  };
  
