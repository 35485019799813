import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../login/login.module.scss";
import BrawlerFAQs from "../faqs/brawler-faqs";

const ShowResults: React.FC = () => {
  const navigate = useNavigate();

  const linkTarget = "/positive-ev";
  const handleTryIt = () => {
    navigate("/positive-ev");
  };

  return (
    <div>   
      <FeatureHighlights />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <div className="text-center w-4/5 mx-auto headline-m">
          <h1 className="font-extrabold text-brand-gray-9">
            <div className="lg:text-6xl md:text-4xl text-3xl my-1 mb-4">
              BookieBrawler gives you the <span className="text-powder-bloo">tools</span> and{" "}
              <span className="text-powder-bloo">knowledge</span> to make betting
              into a <span className="text-powder-bloo">side hustle</span>
            </div>
            <div className="mt-3">
              {"  "}(at a fraction of the cost of those other guys)
            </div>
          </h1>
        </div>
      </div>
     
      <div className="flex flex-col sm:flex-row pt-4">
        <div className="flex-1 flex justify-center items-center overflow-auto p-8">
          <div className="rounded-lg overflow-hidden shadow-lg max-w-sm text-center p-6 light-brder slate-dark-transparent">
            <img
              src={"https://storage.googleapis.com/assestsbookiebrawler/images/testimonial-bloo.png"}
              alt="Profile"
              className="mx-auto mb-2"
              style={{ width: "250px", height: "auto" }}
            />
            <div className="flex items-center justify-start mb-4 w-full extra-light-btm-brder">
              <div className="font-code-next pr-2">I made</div>
              <div className={styles.shine + " m-0 font-code-next text-2xl"}>
                $50 per hour
              </div>
              <hr className="my-4" />
            </div>
            <div className="flex items-center justify-start mb-4 w-full extra-light-btm-brder pb-2">
              <div className={styles.shine + " font-code-next text-2xl pr-2 flex-shrink-0"}>
                37x'd
              </div>
              <div className="font-code-next text-left flex-grow">
                my subscription cost with less than 15 hours of work
              </div>
            </div>
            <p className="">— Bloo</p>
          </div>
        </div>
        <div className="flex-1 flex justify-center items-center px-4 mt-5 py-3">
          <div className="flex flex-col justify-center items-center">
            <div>
              <h2 className="text-3xl mb-4 text-center">
                How much money can I actually make?
              </h2>
              <p className="mb-4 text-powder-bloo">
                So far, our bettors are bringing in a daily average of about{" "}
                <span className={styles.shine + " font-bold"} style={{ fontSize: "1rem" }}>
                  9.95%
                </span>
                . This kind of return could see your funds more than double by
                month's end. (thanks to the power of compounding returns).
              </p>
              <p className="mb-4 text-powder-bloo">
                Setting aside the complex calculations, simply engaging with our
                platform could lead to a profit of at least{" "}
                <span className={styles.shine + " font-bold"} style={{ fontSize: "1rem" }}>
                  $500 to $1800 each month
                </span>
                .
              </p>
              <p className="mb-4 text-powder-bloo">
                Top bettors can make{" "}
                <span className={styles.shine + " font-bold"} style={{ fontSize: "1rem" }}>
                  several thousand per month
                </span>
                .
              </p>
              <div className="flex justify-center">
                <Link to={linkTarget} className="no-underline">
                  <button className="ctaButton">Try it out</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowResults;

const FeatureHighlights: React.FC = () => {
  return (
    <div className="flex justify-center">
    <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 py-2 px-2 md-brder rounded bg-[#011d47e8] text-white w-fit m-5 text-sm">
      <div className="flex items-center space-x-2 px-2">
        <img src="https://storage.googleapis.com/assestsbookiebrawler/icons/video-icon.png" alt="Video Icon" className="w-5 h-5" />
        <span>10+ tutorial videos</span>
      </div>
      <div className="border-t sm:border-t-0 sm:border-l border-gray-600 w-full sm:w-auto h-0 sm:h-6"></div>
      <div className="flex items-center space-x-2 px-2">
        <img src="https://storage.googleapis.com/assestsbookiebrawler/icons/group-icon-new.png" alt="Coaching Icon" className="w-5 h-5" />
        <span>Free Coaching Sessions</span>
      </div>
      <div className="border-t sm:border-t-0 sm:border-l border-gray-600 w-full sm:w-auto h-0 sm:h-6"></div>
      <div className="flex items-center space-x-2 px-2">
        <img src="https://storage.googleapis.com/assestsbookiebrawler/icons/shield-icon-new.png" alt="Trial Icon" className="w-5 h-5" />
        <span>Money Back Guarantee</span>
      </div>
    </div>
    </div>
  );
};



export const LearningPrompt: React.FC = () => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-between py-4 px-6 md-brder rounded-lg bg-[#011d47e8] text-white max-w-[1300px] w-[88%]  mx-auto m-5">
      <div className="flex items-center space-x-2">
        <span className="text-xl text-powder-bloo font-bold">Seems like a lot?</span>
      </div>
      <div className="flex items-center space-x-2 py-3">
        <img src="https://storage.googleapis.com/assestsbookiebrawler/icons/education-icon.png" alt="Education Icon" className="w-auto h-[30px]" />
        <span>We have dozens of educational videos and a team of free expert coaches to help</span>
      </div>
      <div className="hidden sm:block border-l border-gray-600 h-6 mx-4"></div>
      <div className="flex justify-center sm:justify-end w-full sm:w-auto">
      <a
      href="/betting-education"
      title="Start Learning Now"
      className="mb-2"
    >
     <button className="bg-powder-bloo text-[#011d47e8] font-bold py-2 px-4  rounded">
  Start Learning Now
</button>
    </a>
        
      </div>
    </div>
  );
};



