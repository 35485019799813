import {
  createFigCaption,
  createFormattedDateParagraph,
  renderPostImage,
} from "../../../services/common-util-service";
import { populate } from "dotenv";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { ContentPost, getRelevantPosts } from "./content-post-model";
import ProfileImage from "../../account/ProfileImage";
import ShareButton from "./share-content-button";

interface ContentPostViewProps {
  contentType?: string;
}

const ContentPostView: React.FC<ContentPostViewProps> = ({ contentType }) => {
  const { slug } = useParams<{ slug: string }>();

  const relevantPost = getRelevantPosts(contentType);

  const post = relevantPost.find((p: ContentPost) => p.slug === slug);

  return (
    <HelmetProvider>
      <div className="responsive-content-margin">
        {post ? (
          <>
            <Helmet>
              <title>{post.title} | BookieBrawler</title>
              <meta name="description" content={post.summary} />
            </Helmet>
            <div className="w-full lg:w-3/4 mb-5">
              <h1 className="tracking-wide font-extrabold text-left lg:text-4xl md:text-2xl text-xl font-code-next text-brand-gray-9">
                {post.title}
              </h1>
              <div className="flex items-center justify-between mt-2">
                <div>{createFormattedDateParagraph(post?.publishedDate ?? new Date(), post.author)}</div>
                <ShareButton content={post} /> 
              </div>
              {renderPostImage(post)}
              <p>{post.content}</p>
            </div>
          </>
        ) : (
          <>
            <Helmet>
              <title>Content Not Found | BookieBrawler</title>
              <meta
                name="description"
                content="The blog post you are looking for does not exist."
              />
            </Helmet>
            <p>Content not found.</p>
          </>
        )}
      </div>
    </HelmetProvider>
  );
};

export default ContentPostView;
