import React, { useState } from "react";
import { Button } from "react-bootstrap";

interface League {
  key: string;
  title: string;
}

interface LeagueSelectorProps {
  onChange: (selected: string[]) => void;
  initialLeagues: string[]; // New prop for initial selected leagues
}

const LeagueSelector: React.FC<LeagueSelectorProps> = ({ onChange, initialLeagues }) => {
  // Placeholder leagues array
  const placeholderLeagues = [
    { key: "basketball_nba", title: "NBA" },
    { key: "basketball_wnba", title: "WNBA" },
    { key: "baseball_mlb", title: "MLB" },
    { key: "basketball_ncaab", title: "NCAAB" },
    { key: "americanfootball_nfl", title: "NCAAB" },
    { key: "americanfootball_ncaa", title: "NCAAB" },
    { key: "soccer_mls", title: "MLS" }
  ];

  const [leagues, setLeagues] = useState<League[]>(placeholderLeagues);
  const [selectedLeagues, setSelectedLeagues] = useState<string[]>(initialLeagues); // Initialize with initialLeagues

  const isLeagueSelected = (
    selectedLeagues: string[],
    league: League
  ): boolean => {
    return selectedLeagues.includes(league.key);
  };

  const toggleLeague = (leagueKey: string) => {
    let updatedSelectedLeagues;
    if (selectedLeagues.includes(leagueKey)) {
      updatedSelectedLeagues = selectedLeagues.filter((key) => key !== leagueKey);
    } else {
      updatedSelectedLeagues = [...selectedLeagues, leagueKey];
    }
    setSelectedLeagues(updatedSelectedLeagues);
    onChange(updatedSelectedLeagues);
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-start ">
        {leagues.map((league, index) => (
          <div key={index} className="text-center p-1">
            <p>{league.title}</p>
            <input
              type="checkbox"
              checked={isLeagueSelected(selectedLeagues, league)}
              onChange={() => toggleLeague(league.key)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default LeagueSelector;
