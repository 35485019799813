import React from 'react';

const Quad1WinInfo: React.FC = () => {
  return (
    <>
      <p className="py-3 text-powder-bloo">
        <h2><strong>What is a Quad 1 Win in College Basketball?</strong></h2>
      </p>
      <p className="py-4 text-white text-opacity-75">
        Each year, the college basketball season builds up to the excitement of March Madness, where 68 teams compete for the national title. This field includes 32 conference champions and 36 at-large bids, which are awarded by a selection committee using advanced metrics, including the NCAA Evaluation Tool (NET) rankings.
      </p>
      <p className="py-4 text-white text-opacity-75">
        According to the NCAA, “The NET includes more components than just winning percentage. It takes into account game results, strength of schedule, game location, scoring margin, net offensive and defensive efficiency, and the quality of wins and losses.” This tool is essential in evaluating teams and their performance throughout the season.
      </p>


      <blockquote className="py-3 pl-4 border-l-4 border-powder-bloo italic text-white text-opacity-75">
      <a href="/positive-ev" className="hover:underline"> “1,000+ sports bettors are making big profits with BookieBrawler. Are you next? Click here to sign up today!”</a>
      </blockquote>
      
      <h3 className="py-4 text-powder-bloo">How Are Games Classified into Quadrants?</h3>
      <p className="py-4 text-white text-opacity-75">
        When assessing a team’s resume, games are categorized into four quadrants based on the opponent’s NET ranking and the game’s location. Here’s how these quadrants break down:
      </p>
      <ul className="list-disc list-inside py-4 text-white text-opacity-75">
        <li><strong>Quad 1:</strong> Home games against opponents ranked in the top 30, neutral site games against opponents ranked in the top 50, and away games against teams ranked in the top 75.</li>
        <li><strong>Quad 2:</strong> Home games against teams ranked 31-75, neutral site games against teams ranked 51-100, and away games against teams ranked 76-135.</li>
        <li><strong>Quad 3:</strong> Home games against teams ranked 76-160, neutral site games against teams ranked 101-200, and away games against teams ranked 135-240.</li>
        <li><strong>Quad 4:</strong> Home games against teams ranked worse than 160, neutral site games against teams ranked worse than 200, and away games against teams outside the top 240.</li>
      </ul>

      <h3 className="py-4 text-powder-bloo">Why Are Quad 1 Wins Important?</h3>
      <p className="py-4 text-white text-opacity-75">
        Quad 1 wins are the most valuable aspect of a team’s resume when competing for an at-large bid in the NCAA Tournament. Beating strong opponents, particularly on the road or at neutral sites, demonstrates a team’s ability to succeed against high-level competition, making these wins crucial for tournament selection.
      </p>
      <p className="py-4 text-white text-opacity-75">
        For instance, Mississippi State is currently “on the bubble,” meaning their NCAA Tournament bid is uncertain. They hold a 3-7 record in Quad 1 games, with notable wins over No. 13 Marquette (neutral site), No. 25 TCU (home), and No. 15 Arkansas (away). However, a Quad 3 loss to No. 138 Georgia negatively impacts their resume.
      </p>
      <p className="py-4 text-white text-opacity-75">
        If Mississippi State can secure additional Quad 1 victories during the regular season or SEC Tournament, their chances of earning an at-large bid increase significantly. For casual fans, think of Quad 1 wins as “good” wins that strengthen a team’s chances for March Madness.
      </p>

      <h3 className="py-4 text-powder-bloo">Summary</h3>
      <p className="py-4 text-white text-opacity-75">
        Understanding the quadrant system in college basketball is crucial for evaluating team performance and predicting NCAA Tournament selections. Quad 1 wins are the most valuable, making them essential for teams on the tournament bubble. By securing these high-quality victories, teams improve their resume and boost their likelihood of making it to March Madness.
      </p>
    </>
  );
};

export default Quad1WinInfo;
