import React, { useEffect, useState } from "react";
import FullScreenVideo from "./FullScreenVideo";
import "./LandingPage.scss";
import "bootstrap/dist/css/bootstrap.css";
import FAQSComponent from "./faqs/Faqs";
import VideoPlayer from "./video-player/VideoPlayer";
import ShowResults, { LearningPrompt } from "./results/show-results";
import WhyUs from "./why-us/why-us";
import { Helmet } from "react-helmet";
import { allFAQs } from "./faqs/faq.constants";
import BrawlerFAQs from "./faqs/brawler-faqs";
import FeatureList from "./feature-list/FeatureList";
import SportsBettingFAQs from "./faqs/sports-betting-101";
import { PromoFinder } from "../products/promo-finder/promo-finder";

interface LandingPageProps {
  authed?: boolean;
}

const LandingPage: React.FC<LandingPageProps> = ({ authed }) => {
  return (
    <>
      {
        // <Helmet>
        //   <title>
        //     BookieBrawler: Turn the Odds in Your Favor: Positive EV Bet Finder |
        //     Odds Comparison |
        //   </title>
        //   <link rel="canonical" href="https://bookiebrawler.com" />
        //   <meta
        //     name="description"
        //     content="At BookieBrawler, we're revolutionizing the way you approach sports betting. Our cutting-edge Sports Betting Tools are engineered to shift the odds in your favor, transforming betting from a game of chance to a calculated strategy. Dive into our suite of tools, including the highly acclaimed Positive EV (Expected Value) Bet Finder, and get ready for the launch of our groundbreaking Arbitrage and Social Community Platform. Designed by seasoned bettors for the betting community, BookieBrawler is your go-to resource for leveraging free bets, gaining unparalleled insights, and standing strong against Sportsbooks."
        //   />
        //   <meta name="robots" content="index,follow"></meta>
        // </Helmet>
 (
        <>
          <FullScreenVideo />
          <ShowResults />
          <PromoFinder/>
          <FeatureList />
          <LearningPrompt />
          <BrawlerFAQs />
          {/* <WhyUs /> */}
          
          <SportsBettingFAQs/>
          <VideoPlayer />
          {/* <FAQSComponent faqs={allFAQs} /> */}
        </>
      )}
    </>
  );
};

export default LandingPage;
