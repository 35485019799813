import React from 'react';
import { getImagePathByMarketKey } from "../../../services/bet-finder-service";

const bookiePromotions: Record<string, string> = {
  "fanduel": "FanDuel - Bet $5, Get $200 in Bonus Bets",
  "williamhill_us": "Caesars - Get up to $1,000 Back in Bonus Bets if Your First Bet Doesn't Win!",
  "lowvig": "LowVig.ag",
  "betonlineag": "BetOnline.ag",
  "wynnbet": "WynnBET",
  "betus": "BetUS",
  "superbook": "SuperBook",
  "mybookieag": "MyBookie.ag",
  "betmgm": "BetMGM",
  "pointsbetus": "PointsBet (US)",
  "twinspires": "TwinSpires",
  "betrivers": "BetRivers",
  "unibet_us": "Unibet",
  "bovada": "Bovada",
  "barstool": "Barstool Sportsbook",
  "draftkings": "DraftKings",
  "gtbets": "GT Bets", 
  "sugarhouse": "Sugar House", 
  "circasports": "Circa Sports", 
  "betfair": "BetFair", 
  "foxbet": "FoxBet",
  "pikkit":"Pikkit",
  "espnbet":"ESPNBet",
  "ballybet":"BallyBet",
  "betparx":"BetParx",
  "fliff":"Fliff",
  "hardrockbet":"HardRock Bet",
  "tipico_us":"Tipico",
  "sisportsbook":"SI Sportsbook",
  "windcreek":"WindCreek",

};


export const bookieSignUpLinks: { [key: string]: string } = {
  "williamhill_us": "https://caesars.com/sportsbook-and-casino/referral?AR=RAF-0D6-NYC",
  "lowvig": "https://sportsbook.lowvig.ag",
  "betonlineag": "https://www.betonline.ag",
  "wynnbet": "https://www.wynnbet.com",
  "betus": "https://www.betus.com.pa",
  "superbook": "https://www.superbook.com",
  "mybookieag": "https://www.mybookie.ag",
  "fanduel": "https://fndl.co/7s5utjf",
  "betmgm": "https://sports.betmgm.com",
  "pointsbetus": "https://pointsbet.com",
  "twinspires": "https://www.twinspires.com",
  "betrivers": "https://www.betrivers.com",
  "unibet_us": "https://www.unibet.com",
  "bovada": "https://www.bovada.lv",
  "barstool": "https://barstoolsportsbook.com",
  "draftkings": "https://sportsbook.draftkings.com",
  "gtbets": "https://www.gtbets.ag/", 
  "sugarhouse": "https://playsugarhouse.com/", 
  "circasports": "https://www.circasports.com/", 
  "betfair": "https://www.betfair.com/",
  "foxbet": "", 
  "espnbet":"https://espnbet.com/",
  "pikkit": "https://links.pikkit.com/invite/basketball49080", 
  "ballybet":"https://www.ballybet.com/",
  "betparx":"https://www.betparx.com/",
  "fliff":"https://get.fliffapp.com/QlC3/h630rubi",
  "hardrockbet":"https://www.hardrock.bet/",
  "tipico_us":"https://www.tipico.com/us",
  "sisportsbook":"https://www.sisportsbook.com/",
  "windcreek":"",
  "parlayplay":"https://parlayplay.io/",
  "thrillzz":"https://thrillzz.com/",
  "rebet":"https://rebet.app/",
};



export const BookieImage = ({ bookie }: { bookie: string }) => {
  const imagePath = getImagePathByMarketKey(bookie);
  return (
    <a href={bookieSignUpLinks[bookie] || '#'} title={bookiePromotions[bookie] || 'Sign Up'}  style={{ display: 'inline-block' }} >
      <img
        src={imagePath}
        alt={`${bookie} logo`}
        className="rounded transition-transform duration-300 hover:scale-110" 
        style={{
          borderRadius: '0.25rem',
          height: '27px',
          width: '27px',
          maxWidth: '57px', 
          maxHeight: '57px',
          marginRight: '8px',
        }}
      />
    </a>
  );
};
