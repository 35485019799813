export interface BookiePromo {
    bookieKey: string;          // Identifier for the sportsbook
    name: string;               // Name of the sportsbook
    promoAmount: number;        // Monetary amount of the promo
    promoDescription: string;   // Description of the promotion
    affiliateCode?: string;     // Optional affiliate code
    promoCode?: string;         // Optional promo code
    states: string[];           // List of states where the promo is available (all lowercase)
  }
  

  // Array of all 50 U.S. states in lowercase
const allStates: string[] = [
    'alabama', 'alaska', 'arizona', 'arkansas', 'california', 'colorado',
    'connecticut', 'delaware', 'florida', 'georgia', 'hawaii', 'idaho',
    'illinois', 'indiana', 'iowa', 'kansas', 'kentucky', 'louisiana', 'maine',
    'maryland', 'massachusetts', 'michigan', 'minnesota', 'mississippi',
    'missouri', 'montana', 'nebraska', 'nevada', 'new hampshire', 'new jersey',
    'new mexico', 'new york', 'north carolina', 'north dakota', 'ohio',
    'oklahoma', 'oregon', 'pennsylvania', 'rhode island', 'south carolina',
    'south dakota', 'tennessee', 'texas', 'utah', 'vermont', 'virginia',
    'washington', 'west virginia', 'wisconsin', 'wyoming'
  ];
  
  // Sample BookiePromo data with promoAmount
  const bookiePromos: BookiePromo[] = 
  [
    {
      bookieKey: "draftkings",
      name: "DraftKings",
      promoAmount: 250,
      promoDescription: "Bet $5 and Get $250 in Bonus Bets + 1 Month of NFL+ Premium",
      states: ["arizona", "colorado", "connecticut", "washington d.c.", "illinois", "indiana", "iowa", "kansas", "kentucky", "louisiana", "massachusetts", "maryland", "maine", "michigan", "new hampshire", "new jersey", "new york", "north carolina", "ohio", "oregon", "pennsylvania", "tennessee", "virginia", "vermont", "west virginia", "wyoming"]
    },
    {
      bookieKey: "fanduel",
      name: "FanDuel",
      promoAmount: 200,
      promoDescription: "Bet $5 Get $200 in Bonus Bets + 3 Weeks of NFL Sunday Ticket",
      states: ["arizona", "colorado", "washington d.c.", "illinois", "indiana", "iowa", "kansas", "kentucky", "louisiana", "massachusetts", "maryland", "michigan", "mississippi", "nevada", "new jersey", "new york", "ohio", "pennsylvania", "tennessee", "virginia", "west virginia", "wyoming"]
    },
    {
      bookieKey: "williamhill_us",
      name: "Caesars",
      promoAmount: 1000,
      promoDescription: "Up To $1,000 Bonus Bet",
      states: ["arizona", "colorado", "washington d.c.", "illinois", "indiana", "iowa", "kansas", "kentucky", "louisiana", "maine", "maryland", "massachusetts", "michigan", "new jersey", "new york", "north carolina", "ohio", "pennsylvania", "tennessee", "virginia", "west virginia", "wyoming"]
    },
    {
      bookieKey: "fanatics",
      name: "Fanatics",
      promoAmount: 1000,
      promoDescription: "Bet and Get Up to $1000 in Bonus Bets",
      states: ["washington d.c."]
    },
    {
      bookieKey: "betmgm",
      name: "BetMGM",
      promoAmount: 1500,
      promoDescription: "Up To $1,500 in Bonus Bets Paid Back If Your First Bet Does Not Win",
      states: ["arizona", "colorado", "washington d.c.", "illinois", "indiana", "iowa", "kansas", "louisiana", "maryland", "michigan", "mississippi", "new jersey", "nevada", "new york", "ohio", "pennsylvania", "tennessee", "virginia", "west virginia", "wyoming"]
    },
    {
      bookieKey: "betrivers",
      name: "BetRivers",
      promoAmount: 500,
      //promoCode: "SBRBONUS",
      promoDescription: "Up to a $500 Second Chance Bet",
      states: ["arizona", "colorado", "delaware", "indiana", "iowa", "illinois", "louisiana", "maryland", "michigan", "new jersey", "new york", "ohio", "pennsylvania", "virginia", "west virginia"]
    },
    {
      bookieKey: "superbook",
      name: "Superbook",
      promoAmount: 250,
      promoDescription: "Get a First Bet Match up to $250 for Any Market",
      states: ["arizona", "colorado", "new jersey", "tennessee", "iowa", "ohio", "maryland"]
    },
    {
      bookieKey: "fliff",
      name: "Fliff",
      promoAmount: 100,
      promoDescription: "Spend $50, Get $100 in Coins and FREE Fliff Cash!",
      states: ["alaska", "arizona", "arkansas", "california", "colorado", "connecticut", "delaware", "florida", "illinois", "kansas", "maine", "maryland", "massachusetts", "missouri", "montana", "nebraska", "new hampshire", "new jersey", "new mexico", "new york", "north carolina", "north dakota", "oklahoma", "oregon", "pennsylvania", "rhode island", "south dakota", "texas", "utah", "vermont", "virginia", "west virginia", "wisconsin", "wyoming"]
    },
    {
      bookieKey: "thrillzz",
      name: "Thrillzz",
      promoAmount: 25,
      promoDescription: "100% Match up to $25 in Coins + $3 Free Coins",
      states: ["alaska", "arkansas", "arizona", "california", "colorado", "connecticut", "delaware", "florida", "hawaii", "illinois", "kansas", "massachusetts", "maryland", "maine", "michigan", "missouri", "montana", "north carolina", "north dakota", "nebraska", "new hampshire", "new jersey", "new mexico", "new york", "oklahoma", "oregon", "pennsylvania", "rhode island", "south dakota", "tennessee", "texas", "utah", "virginia", "vermont", "wisconsin", "west virginia", "wyoming"]
    },
    {
      bookieKey: "rebet",
      name: "Rebet",
      promoAmount: 100,
      promoDescription: "Get a 100% Deposit Match of up to 100 Rebet Cash!",
      states: ["alabama", "alaska", "arizona", "arkansas", "california", "colorado", "connecticut", "washington d.c.", "delaware", "florida", "georgia", "hawaii", "illinois", "indiana", "iowa", "kansas", "kentucky", "louisiana", "maine", "maryland", "massachusetts", "minnesota", "mississippi", "missouri", "montana", "nebraska", "new hampshire", "new jersey", "new mexico", "new york", "north carolina", "north dakota", "ohio", "oklahoma", "oregon", "pennsylvania", "rhode island", "south carolina", "south dakota", "tennessee", "texas", "utah", "vermont", "virginia", "washington", "west virginia", "wisconsin", "wyoming"]
    },
    {
      bookieKey: "parlayplay",
      name: "ParlayPlay",
      promoAmount: 100,
      promoDescription: "Sign up for ParlayPlay and Get $100!",
      states: ["wisconsin", "washington d.c.", "utah", "texas", "south dakota", "south carolina", "rhode island", "oregon", "oklahoma", "new mexico", "nebraska", "north dakota", "north carolina", "kentucky", "kansas", "illinois", "georgia", "florida", "california", "alaska", "maryland"]
    }  
  ]
  

  function getPromosByStateGivenPromos(state: string, promos: BookiePromo[]): BookiePromo[] {
    return promos.filter(promo => 
      promo.states.includes(state.toLowerCase()) || promo.states.includes('all')
    );
  }

  export function getPromosByState(state: string): BookiePromo[] {
    return getPromosByStateGivenPromos(state,bookiePromos);
  }
  

  export async function getUserUSState(): Promise<string> {
    if (!navigator.geolocation) {
      return ""; // Geolocation is not supported
    }
  
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
  
        // Use OpenStreetMap's Nominatim for reverse geocoding
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`
        );
  
        const data = await response.json();
  
        // Check if the address has a country and state, and if the country is the US
        const country = data.address.country_code?.toUpperCase();
        const state = data.address.state;
  
        if (country === "US" && state) {
          resolve(state);
        } else {
          resolve(""); // User is not in the US
        }
      }, () => {
        resolve(""); // If geolocation fails or is denied
      });
    });
  }
  