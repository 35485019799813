import { useState } from "react";
import { FAQ } from "./faqs.types";



interface FAQListProps {
    faqs: FAQ[];
  }
  
  const FAQList: React.FC<FAQListProps> = ({ faqs }) => {
    return (
      <div className="lg:max-w-[1200px] mx-auto">
        {faqs.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    );
  };
  
  
  interface FAQItemProps {
    question: string;
    answer: string;
  }
  
  const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleFAQ = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="border-b border-gray-600">
        <button
          className="w-full text-left py-4 px-6 flex justify-between items-center focus:outline-none"
          onClick={toggleFAQ}
        >
          <span>{question}</span>
          <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
            <img src={"https://storage.googleapis.com/assestsbookiebrawler/icons/caret-icon-new.png"}></img>
          </span>
        </button>
        {isOpen && (
          <div className="px-6 pb-4">
            <p>{answer}</p>
          </div>
        )}
      </div>
    );
  };
  
  export default FAQList;
  