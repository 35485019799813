import React, { useState, useEffect } from 'react';

const RoundRobinCalculator: React.FC = () => {
  const [stake, setStake] = useState<number>(10);
  const [numGames, setNumGames] = useState<number>(5);
  const [parlaySize, setParlaySize] = useState<number>(2);
  const [odds, setOdds] = useState<number[]>(Array(10).fill(-110));
  const [outcomes, setOutcomes] = useState<string[]>(Array(10).fill("Win"));
  const [maxWin, setMaxWin] = useState<number>(0);
  const [totalRisk, setTotalRisk] = useState<number>(0);
  const [simulatedResult, setSimulatedResult] = useState<number>(0);

  useEffect(() => {
    setOdds((prevOdds) => {
      const updatedOdds = [...prevOdds];
      while (updatedOdds.length < numGames) {
        updatedOdds.push(-110);
      }
      return updatedOdds.slice(0, numGames);
    });
    setOutcomes((prevOutcomes) => {
      const updatedOutcomes = [...prevOutcomes];
      while (updatedOutcomes.length < numGames) {
        updatedOutcomes.push("Win");
      }
      return updatedOutcomes.slice(0, numGames);
    });
  }, [numGames]);

  const calculatePayout = (odd: number): number => {
    return odd < 0 ? (100 / Math.abs(odd)) + 1 : (odd / 100) + 1;
  };

  const getCombinations = (arr: number[], size: number): number[][] => {
    const results: number[][] = [];
    if (size > arr.length) return results;

    const combination = (start: number, path: number[]) => {
      if (path.length === size) {
        results.push(path);
        return;
      }
      for (let i = start; i < arr.length; i++) {
        combination(i + 1, path.concat(arr[i]));
      }
    };

    combination(0, []);
    return results;
  };

  const calculateRoundRobin = () => {
    const numParlays = getCombinations(Array.from({ length: numGames }, (_, i) => i), parlaySize).length;
    const totalBet = stake * numParlays;
    setTotalRisk(totalBet);

    const maxWin = numParlays * (Math.pow(calculatePayout(odds[0]), parlaySize) * stake - stake);
    setMaxWin(maxWin);

    calculateSimulatedResult();
  };

  const calculateSimulatedResult = () => {
    // Step 1: Filter indices for games that are marked as "Win"
    const winningIndices = odds
      .map((_, index) => (outcomes[index] === "Win" ? index : null))
      .filter((index) => index !== null) as number[];
  
    // If we don't have enough winning games to form a full parlay, set result to 0
    if (winningIndices.length < parlaySize) {
      setSimulatedResult(0);
      return;
    }
  
    // Step 2: Generate all possible winning parlays (combinations of winning games)
    const winningParlays = getCombinations(winningIndices, parlaySize);
    let totalReturn = 0;
  
    // Step 3: Calculate the total return for each winning parlay
    for (const parlay of winningParlays) {
      const parlayPayoutMultiplier = parlay.reduce(
        (acc, index) => acc * calculatePayout(odds[index]),
        1
      );
      const parlayReturn = parlayPayoutMultiplier * stake;
      totalReturn += parlayReturn;
    }
  
    // Step 4: Calculate total risk (stakes from all parlays)
    const numParlays = getCombinations(
      Array.from({ length: numGames }, (_, i) => i),
      parlaySize
    ).length;
    const totalBet = stake * numParlays;
  
    // Step 5: Calculate net profit by subtracting total stake from total return
    const netProfit = totalReturn - totalBet;
  
    // Step 6: Update the simulated result state
    setSimulatedResult(parseFloat(netProfit.toFixed(2)));
  };
  
  
  useEffect(() => {
    calculateRoundRobin();
  }, [stake, numGames, parlaySize, odds, outcomes]);

  const handleOddsChange = (index: number, value: string) => {
    const newOdds = [...odds];
    newOdds[index] = Number(value);
    setOdds(newOdds);
  };

  const handleOutcomeChange = (index: number, value: string) => {
    const newOutcomes = [...outcomes];
    newOutcomes[index] = value;
    setOutcomes(newOutcomes);
    calculateSimulatedResult();
  };

  return (
    <div className="container min-w-[320px] max-w-[700px] p-4 bg-gray-800 rounded-lg text-white border-[0.5px]">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <span className="w-1/2 font-bold text-sm">Stake</span>
          <div className="relative w-1/2">
            <input
              type="number"
              value={stake}
              onChange={(e) => setStake(Number(e.target.value))}
              className="w-full p-2 bg-gray-900 rounded"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className="w-1/2 font-bold text-sm">Number of Games</span>
          <div className="relative w-1/2">
            <select
              value={numGames}
              onChange={(e) => setNumGames(Number(e.target.value))}
              className="w-full p-2 bg-gray-900 rounded"
            >
              {Array.from({ length: 15 }, (_, i) => i + 1).map((number) => (
                <option key={number} value={number}>
                  {number}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center">
          <span className="w-1/2 font-bold text-sm">Parlay Size</span>
          <div className="relative w-1/2">
            <select
              value={parlaySize}
              onChange={(e) => setParlaySize(Number(e.target.value))}
              className="w-full p-2 bg-gray-900 rounded"
            >
              {Array.from({ length: 4 }, (_, i) => i + 1).map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        {Array(numGames)
          .fill(null)
          .map((_, index) => (
            <div key={index} className="flex items-center gap-2">
              <span className="w-1/2 font-bold text-sm">Game #{index + 1} Odds</span>
              <div className="relative w-1/4">
                <input
                  type="number"
                  value={odds[index]}
                  onChange={(e) => handleOddsChange(index, e.target.value)}
                  className="w-full p-2 bg-gray-900 rounded"
                />
              </div>
              <div className="relative w-1/4">
                <select
                  value={outcomes[index]}
                  onChange={(e) => handleOutcomeChange(index, e.target.value)}
                  className="w-full p-2 bg-gray-900 rounded"
                >
                  <option value="Win">Win</option>
                  <option value="Lose">Lose</option>
                </select>
              </div>
            </div>
          ))}
        <div className="grid grid-cols-3 gap-4 mt-1">
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Max Win</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              ${maxWin.toFixed(2)}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Total Risk</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              ${totalRisk.toFixed(2)}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="font-bold text-sm">Simulated Result</span>
            <div className="rounded h-8 flex items-center justify-center text-sm font-medium">
              ${simulatedResult.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundRobinCalculator;
