"use client";

import { ColumnDef } from "@tanstack/react-table";
import { Bet, PositiveEVRow } from "../types/positive-ev.types";
import {
  convertUTCtoUserTimezone,
  getBetCalcValues,
} from "../services/positive-ev-service";
import EvPercentsCell from "./cells/ev-percents-cell";
import BetSizesCell from "./cells/bet-sizes-cell";
import BestBetsCell from "./cells/bets-cell";
import BookiesCell from "./cells/bookies-cell";
import FairOddsCell from "./cells/fair-odds-cell";
import styles from "../../../login/login.module.scss";
import BankrollContainer from "./bankroll/bankroll-container";
import ESPNGameLink from "../../common/espn-game-link";
import BetCalcModal from "./bet-calculator/bet-calculator-modal";
import { BetCalculatorValues } from "../types/positive-ev.types";
import ModalToggle from "./bet-calculator/bet-calc-modal-toggle";
import MarketView from "./market-view/market-view";
import React from "react";

export const columns: ColumnDef<PositiveEVRow>[] = [

  
  {
    accessorKey: "bets",
    header: "",
    cell: ({ row }) => {
      const betCalcValues = getBetCalcValues(row.original);
      return <ModalToggle betCalcValues={betCalcValues} />;
    },
  },
  {
    accessorKey: "ev_percents",
    header: () => <span className="text-powder-bloo-two">PERCENT</span>,
    cell: ({ row }) => (
      <EvPercentsCell evPercents={row.getValue("ev_percents")} />
    ),
  },
  {
    accessorKey: "bet_sizes",
    header: () => (
      <>
        <div className="text-powder-bloo-two">REC. BET SIZE</div>
        <div className="tip2">
          <img
            src="https://storage.googleapis.com/assestsbookiebrawler/icons/tool-tip-icon.svg"
            alt="Info Icon"
            className="w-4" // Tailwind classes for width and height
          />
          <span className="tooltiptext absolute right-0 ml-2 shadow-lg p-2 rounded-md ">
            {
              <p>
                <strong>BookieBrawler</strong> computes the ideal bet amounts
                utilizing the well-known Kelly Criterion. Enter your bankroll,
                and we'll determine the optimal stake for each bet with Positive
                EV for you.
              </p>
            }
            <BankrollContainer />
          </span>
        </div>
      </>
    ),
    cell: ({ row }) => (
      <BetSizesCell betSizes={row.getValue("bet_sizes") as number[]} />
    ),
  },
  {
    accessorKey: "event_date",
    header: () => <div className="text-powder-bloo-two">EVENT DATE</div>,
    cell: ({ row }) => {
      const utcDateStr = row.getValue("event_date") as string;
      const formattedDate = convertUTCtoUserTimezone(utcDateStr);
      return <div>{formattedDate}</div>;
    },
  },
  {
    accessorKey: "event",
    header: () => <div className="text-powder-bloo-two">EVENT</div>,
  },
  {
    accessorKey: "market",
    header: () => <div className="text-powder-bloo-two">MARKET</div>,
    cell: ({ row }) => {
      return (
        <div>
          {" "}
          {row.original.game_link && row.original.game_link.includes("espn")
            ? "Moneyline"
            : row.original.market}
        </div>
      );
    },
  },
  {
    accessorKey: "bets",
    header: () => <span className="text-powder-bloo-two">BETS</span>,
    cell: ({ row }) => <BestBetsCell positiveEVRow={row.original} />,
  },
  {
    accessorKey: "bets",
    header: () => <span className="text-powder-bloo-two">BOOKIES</span>,
    cell: ({ row }) => {
      // const [isModalOpen, setIsModalOpen] = React.useState(false);
  
      // const toggleModal = () => setIsModalOpen(!isModalOpen);
  
      return (
        <>
          <BookiesCell positiveEVRow={row.original} />
          {/* <MarketView evRow={row.original} isOpen={isModalOpen} toggleModal={toggleModal} /> */}
        </>
      );
    },
  },
  
  {
    accessorKey: "fair_odds",
    header: () => (
      <>
        <div className="text-powder-bloo-two">FAIR ODDS </div>
        <div>
          <span className="tip">
            <img
              src="https://storage.googleapis.com/assestsbookiebrawler/icons/tool-tip-icon.svg"
              alt="Info Icon"
              style={{ width: "16px", height: "16px", marginLeft: "8px" }}
            />
            <span className="tooltiptext">
              <p>
                <strong>FAIR ODDS:</strong>
              </p>
              <p>
                <strong>BookieBrawler</strong> finds value bets with our constantly improving
                proprietary method for calculating fair odds.
              </p>

              {/* <p>
                <a
                  href="https://www.espn.com/nba/story/_/id/13984129/what-espn-nba-basketball-power-index"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more about ESPN BPI
                </a>
              </p> */}
            </span>
          </span>
        </div>
      </>
    ),
    cell: ({ row }) => (
      <FairOddsCell fairOdds={row.getValue("fair_odds") as number[]} />
    ),
  },
  {
    accessorKey: "game_link",
    header: () => <div className="text-powder-bloo-two">STATS</div>,
    cell: ({ row }) => {
      const gameLink = row.getValue("game_link") as string;
      return <ESPNGameLink navigateUrl={gameLink} />;
    },
  },
];
