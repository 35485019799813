import React from "react";
import { ContentPost, getContentPostUrl } from "./content-post-model";
//import { ContentPost, getContentPostUrl } from "./path-to-your-file";

interface ShareButtonProps {
  content: ContentPost; // The content post to be shared
}

const ShareButton: React.FC<ShareButtonProps> = ({ content }) => {
  const handleShare = async () => {
    const postUrl = getContentPostUrl(content.slug);

    if (!postUrl) {
      alert("Unable to generate a shareable URL for this content.");
      return;
    }

    if (navigator.share) {
      try {
        await navigator.share({
          title: content.title,
          text: content.summary,
          url: postUrl,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      // Fallback for browsers without Web Share API support
      alert(`Copy this link to share: ${postUrl}`);
    }
  };

  return (
    <button
      onClick={handleShare}
      className="font-bold py-2 px-3 rounded shadow-md bg-powder-bloo text-[#0A1E3B] text-[14px] font-bold flex items-center space-x-2"
    >
      <img
        src="https://storage.googleapis.com/assestsbookiebrawler/icons/share-icon.png"
        alt="Share Icon"
        className="w-6 h-6" // Adjust size as needed
      />
      <span>Share</span>
    </button>
  );
};

export default ShareButton;
