import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  DEFAULT_ODDS,
  determineFontSizeForOdds,
  fetchAndConvertMarketView,
  formatOdds,
  getBackgroundColorFromOdds,
  getGreatestOdds,
  getPointFromBetType,
  transformToMarketViewDTO,
} from "./market-view-service";
import { getImagePathByMarketKey } from "../../../../../services/bet-finder-service";
import LinearIndeterminate from "../../../../../components/products/common/loading/linear-indeterminate";

interface MarketViewProps {
  evRow?: any;
  isOpen: boolean;
  toggleModal: () => void;
}

export const renderError = (): JSX.Element => {
  return (
    <div
      className="ev-red ev-red-border shadow-md hover:shadow-lg transition-shadow duration-300 px-4 py-3 rounded fit-content"
      role="alert"
    >
      <strong className="font-bold text-red-500">ERROR:</strong>
      <span className="">
        {" "}
        Something went wrong. Please try logging in again.
      </span>
    </div>
  );
};

const MarketView: React.FC<MarketViewProps> = ({
  isOpen,
  toggleModal,
  evRow,
}) => {
  const [odds, setOdds] =
    useState<[string, number | undefined, number | undefined][]>(DEFAULT_ODDS);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const marketViewModel = transformToMarketViewDTO(evRow);

  const { labelTop, labelBottom } = getPointFromBetType(
    marketViewModel.bet_type
  );

  const fairOdds: [number, number] = [
    evRow.fair_odds_away,
    evRow.fair_odds_home,
  ];
  const bestOdds: [number, number] = getGreatestOdds(odds);

  useEffect(() => {
    if (isOpen && evRow) {
      // Fetch the market view data when the modal opens
      const fetchOdds = async () => {
        try {
          setIsLoading(true);
          const fetchedOdds = await fetchAndConvertMarketView(marketViewModel);
          setIsLoading(false);
          setOdds(fetchedOdds);
        } catch (error) {
          setError(true);
          console.error("Error fetching and converting market view:", error);
          // Assuming you have an error state to handle rendering the error message
        }
      };

      fetchOdds();
    }
  }, [isOpen, evRow]);

  useEffect(() => {
    //setError(odds.length === 0);
  }, [odds]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 dark-bloo-bg">
      <div className="bg-[#01183a] rounded-lg p-6 max-w-full md:max-w-fit ">
        <div className="flex justify-between  mb-4">
          <h5 className="tracking-wider mb-4">
            <strong>{marketViewModel.description + " " + evRow.market}</strong>
          </h5>
          <div className="min-h-[50px] w-[65%]">    
           { isLoading && <LinearIndeterminate />}
          </div>
  
        </div>
        <div className="flex space-x-4 overflow-auto max-h-full">
          <OddsLayout
            labelTop={labelTop}
            labelBottom={labelBottom}
            fairOdds={fairOdds}
            bestOdds={bestOdds}
          />
      
          {error && renderError()}
            {/* { isLoading && <LinearIndeterminate />} */}
          <div className="bookie-columns">
            
            <div className="flex space-x-5 min-w-max text-center">
              {odds.map(([bookie, away, home], index) => (
                <div key={index} className="flex flex-col">
                  <OddsColumn
                    bookie={bookie}
                    away={away}
                    home={home}
                    fairAway={fairOdds[0]}
                    fairHome={fairOdds[1]}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketView;

interface OddsColumnProps {
  bookie: string;
  away?: number;
  home?: number;
  fairAway: number;
  fairHome: number;
}

const OddsColumn: React.FC<OddsColumnProps> = ({
  away,
  home,
  fairAway,
  fairHome,
  bookie,
}) => {
  return (
    <div className="flex flex-col items-center rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <img
        src={getImagePathByMarketKey(bookie)}
        alt="Sportsbook"
        className="mb-[72px] w-[52px] h-[52px] rounded"
      />
      <div
        className={`p-2 rounded-top border-b text-white w-[52px] ${getBackgroundColorFromOdds(
          fairAway,
          away
        )} ${determineFontSizeForOdds(away)}`}
      >
        {formatOdds(away)}
      </div>
      <div
        className={`p-2 rounded-bottom border-t text-white w-[52px] ${getBackgroundColorFromOdds(
          fairHome,
          home
        )} ${determineFontSizeForOdds(home)}`}
      >
        {formatOdds(home)}
      </div>
    </div>
  );
};

interface FairOddsColumnProps {
  fairAway: number;
  fairHome: number;
}

const FairOddsColumn: React.FC<FairOddsColumnProps> = ({
  fairAway,
  fairHome,
}) => {
  return (
    <span className="">
      <div
        className={`p-2 rounded-top border-b ${getBackgroundColorFromOdds(
          fairAway,
          fairAway
        )} text-white w-[52px] shadow-md hover:shadow-lg transition-shadow duration-300 ${determineFontSizeForOdds(
          fairAway
        )}`}
      >
        {formatOdds(fairAway)}
      </div>
      <div
        className={`p-2 rounded-bottom border-t ${getBackgroundColorFromOdds(
          fairHome,
          fairHome
        )} text-white w-[52px] ${determineFontSizeForOdds(fairHome)}`}
      >
        {formatOdds(fairHome)}
      </div>
    </span>
  );
};

interface BestOddsColumnProps {
  bestAway: number;
  bestHome: number;
  fairAway: number;
  fairHome: number;
}

const BestOddsColumn: React.FC<BestOddsColumnProps> = ({
  bestAway,
  bestHome,
  fairAway,
  fairHome,
}) => {
  return (
    <span className="">
      <div
        className={`p-2 rounded-top border-b text-white w-[52px] shadow-md hover:shadow-lg transition-shadow duration-300 ${getBackgroundColorFromOdds(
          fairAway,
          bestAway
        )} ${determineFontSizeForOdds(bestAway)}`}
      >
        {formatOdds(bestAway)}
      </div>
      <div
        className={`p-2 rounded-bottom border-t text-white w-[52px] ${getBackgroundColorFromOdds(
          fairHome,
          bestHome
        )} ${determineFontSizeForOdds(bestHome)}`}
      >
        {formatOdds(bestHome)}
      </div>
    </span>
  );
};

const OddsLayout: React.FC<{
  labelTop: string;
  labelBottom: string;
  fairOdds: [number, number];
  bestOdds: [number, number];
}> = ({ labelTop, labelBottom, fairOdds, bestOdds }) => {
  return (
    <div className="">
      <div className="text-[11px] ml-[96px] mb-[84px] font-semibold uppercase tracking-wider flex space-x-4">
        <div className="max-w-[50px] text-center">Fair Odds</div>
        <div className="max-w-[50px] text-center">Best Odds</div>
      </div>

      <div>
        <div className="flex space-x-4">
          <div className="w-[75px] ">
            <div className="my-2">{labelTop}</div>
            <div className="my-3">{labelBottom}</div>
          </div>

          <FairOddsColumn fairAway={fairOdds[0]} fairHome={fairOdds[1]} />
          <BestOddsColumn
            bestAway={bestOdds[0]}
            bestHome={bestOdds[1]}
            fairAway={fairOdds[0]}
            fairHome={fairOdds[1]}
          />
        </div>
      </div>
    </div>
  );
};
